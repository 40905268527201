import router from "./router";
import header from "./routes/header";
import footer from "./routes/footer";

function domready(callback) {
    if (document.readyState !== "loading") callback();
    else document.addEventListener("DOMContentLoaded", callback);
}

/**
 * Toggle Zenchef modal
 * @param { HTMLElement } presaleModal
 */
function toggleModalPresale(presaleModal) {
    presaleModal.classList.remove("active");
}

domready(() => {
    router({
        edito: "page-template-page-edito",
    }).catch(console.error);

    header();
    footer();

    const presaleModal = document.querySelector("#popup-modal");

    if (presaleModal) {
        const closeModalBtn = presaleModal.querySelector("a.close-popup-modal");

        closeModalBtn.addEventListener("click", () =>
            toggleModalPresale(presaleModal)
        );
    }
});
