export default function footer() {
    const newsletterSubscribedConfirmationModal = document.querySelector('#modal-newsletter-subscribed');

    // Show confirmation modal for a moment
    if (newsletterSubscribedConfirmationModal) {
        setTimeout(() => {
            newsletterSubscribedConfirmationModal.classList.add('active');

            setTimeout(() => {
                newsletterSubscribedConfirmationModal.classList.remove('active');
            }, 2500);
        }, 500);
    }
}
