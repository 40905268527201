/**
 * Toggle Menu
 * @param { HTMLElement} burger
 * @param { HTMLElement} menu
 * @param { HTMLElement} banner
 * @param { HTMLElement} menuImg
 * @param { HTMLElement} body
 * @param { HTMLElement} main
 */
function toggleMenu(burger, menu, banner, menuImg, body) {
    [burger, menu, banner, menuImg].forEach(el => el.classList.toggle('active'));
    body.classList.toggle('fixed');
}

/**
 * Toggle SubMenu
 * @param { HTMLElement} link
 * @param { Array} subMenus
 * @param { Array} arrows
 */
function toggleSubmenu(link, subMenus, arrows) {
    const submenu = link.querySelector('.submenu');
    const arrow = link.querySelector('.submenu-toggler img');

    // deactivate others submenu
    subMenus.forEach((el) => {
        if (el !== submenu) {
            el.classList.remove('active');
            el.style.maxHeight = null;
        }
    });

    // deactivate others arrows
    arrows.forEach((el) => {
        if (el !== arrow) {
            el.classList.remove('active');
            el.style.maxHeight = null;
        }
    });

    // activate selected submenu + arrow
    [submenu, arrow].forEach(el => el.classList.toggle('active'));
    submenu.style.maxHeight = submenu.style.maxHeight ? null : `${submenu.scrollHeight }px`;
}

/**
 * Switch backgrounds
 * @param { String} mode
 * @param { HTMLElement} link
 * @param { HTMLElement} menuImg
 */
function toggleBg(mode, link, menuImg) {
    const allBgs = [...menuImg.querySelectorAll('.bg')];
    allBgs.forEach(bg => bg.classList.remove('active'));

    if (mode === 'change') {
        const { bgUrl } = link.dataset;
        const activeBg = allBgs.find(bg => bg.dataset.bgUrl === bgUrl);
        activeBg.classList.add('active');
    }
}

/**
 * Toggle sticky header
 * @param { HTMLElement} headerWrapper
 */
function toggleStickyHeader(headerWrapper) {
    const { scrollY } = window;

    if (scrollY > 0) headerWrapper.classList.add('fixed');
    else headerWrapper.classList.remove('fixed');
}

export default function header() {
    const body = document.querySelector('body');
    const headerWrapper = document.querySelector('#header');
    const banner = headerWrapper.querySelector('.banner');
    const burger = headerWrapper.querySelector('.burger-menu');

    // Main menu
    const menu = headerWrapper.querySelector('#menu');
    const mainMenu = menu.querySelector('.main-nav');
    const menuImg = menu.querySelector('.left');
    const mainLis = [...mainMenu.querySelectorAll('.link-lvl-1')];
    const mainLinks = [...mainMenu.querySelectorAll('.link-lvl-1 .main-a')];

    // Sub menu
    const mainSubLinks = [...mainMenu.querySelectorAll('.link-lvl-2 .sub-a')];
    const subMenus = [...mainMenu.querySelectorAll('.submenu')];
    const arrows = [...mainMenu.querySelectorAll('.submenu-toggler img')];

    // Secondary menu
    const secondaryMenu = menu.querySelector('.secondary-nav');
    const secondaryLis = [...secondaryMenu.querySelectorAll('.link-lvl-1 a')];

    // Open fullscreen menu
    burger.addEventListener('click', () => toggleMenu(burger, menu, banner, menuImg, body));

    // Main menu
    mainLis.forEach((link) => {
        const submenuToggler = link.querySelector('.submenu-toggler');
        if (submenuToggler) submenuToggler.addEventListener('click', () => toggleSubmenu(link, subMenus, arrows));
    });
    mainLinks.forEach((link) => {
        link.addEventListener('mouseover', () => toggleBg('change', link, menuImg));
        link.addEventListener('mouseout', () => toggleBg('reset', link, menuImg));
    });

    // Submenu
    mainSubLinks.forEach((link) => {
        link.addEventListener('mouseover', () => toggleBg('change', link, menuImg));
        link.addEventListener('mouseout', () => toggleBg('reset', link, menuImg));
    });

    // Secondary menu
    secondaryLis.forEach((link) => {
        link.addEventListener('mouseover', () => toggleBg('change', link, menuImg));
        link.addEventListener('mouseout', () => toggleBg('reset', link, menuImg));
    });

    // Toggle sticky menu
    window.addEventListener('scroll', () => toggleStickyHeader(headerWrapper));
}
